import { ThemeUIStyleObject, Text } from 'theme-ui';

const Price = ({ price, forwardSx }: { price: string | number | null | undefined; forwardSx?: ThemeUIStyleObject }) => {
  if (!price) return null;
  const startsWithDollar = price.toString().startsWith('$');
  return (
    <Text
      as="p"
      sx={{
        ...forwardSx,
        width: 'fit-content',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        lineHeight: ['1.6rem', 1],
        letterSpacing: '-0.01em',
        fontSize: ['1.4rem', '1.8rem'],
        fontWeight: 500,
      }}
    >
      {startsWithDollar ? price : `$${price}`}
    </Text>
  );
};

export default Price;
