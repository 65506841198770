import { FC } from 'react';
import { Box, Flex, Grid, Text } from 'theme-ui';
import ImgLoader from '../../Generic/ImgLoader';
import { TransformedBundle, TransformedKit } from '~/types/filter';
import ProductLink from '../../Product/Link';
import Price from './Price';
import RegularPrice from './RegularPrice';
import ProductOrBundleTitle from './Title';

type Props = {
  bundle: TransformedKit | TransformedBundle;
};
const ShopAllBundleCard: FC<Props> = ({ bundle }) => {
  const isOnSale = (bundle.regularPrice ?? 0) - (bundle.price ?? 0) !== 0;
  const { badge } = bundle;
  return (
    <ProductLink
      collectionSlug={bundle.collection?.[0]?.slug}
      productSlug={bundle.slug}
      productName={bundle.name}
      locationDetail={'Shop All'}
      sx={{
        maxWidth: ['100%', '500px'],
        gridColumn: ['span 2', 'auto'],
        position: 'relative',
        isolation: 'isolate',
      }}
    >
      <Box>
        {(bundle.shopAllImage || bundle.mainImage) && (
          <ImgLoader
            badge={badge}
            image={bundle.shopAllImage ?? bundle.mainImage}
            forwardSx={{ mb: ['1.6rem'], aspectRatio: '1/1' }}
          />
        )}

        <Grid
          sx={{
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            gap: ['.6rem', '.8rem'],
            gridTemplateColumns: '1fr max-content',
          }}
        >
          <ProductOrBundleTitle title={bundle.name} />
          <Price price={bundle.price} forwardSx={{ justifySelf: 'end' }} />
          {bundle.shortDescription && (
            <Text
              sx={{
                fontSize: ['1.4rem', '1.6rem'],
                lineHeight: 1,
                fontWeight: 400,
                letterSpacing: '-0.01em',
                textTransform: 'capitalize',
              }}
              dangerouslySetInnerHTML={{ __html: bundle.shortDescription }}
            />
          )}

          {isOnSale && <RegularPrice regularPrice={bundle.regularPrice} forwardSx={{ justifySelf: 'end' }} />}
          {Boolean(bundle.microloadHighlight) && (
            <Text
              sx={{
                fontSize: ['1.4rem', '1.6rem'],
                lineHeight: 1,
                fontWeight: 400,
                letterSpacing: '-0.01em',
                textTransform: 'capitalize',
              }}
            >
              {bundle.microloadHighlight?.replace(/microload/i, '')}
              6lbs
            </Text>
          )}
        </Grid>
      </Box>
    </ProductLink>
  );
};

export default ShopAllBundleCard;
