import { Text, ThemeUIStyleObject } from 'theme-ui';

const RegularPrice = ({
  regularPrice,
  forwardSx,
}: {
  regularPrice: number | null | undefined;
  forwardSx?: ThemeUIStyleObject;
}) => {
  if (!regularPrice) return null;
  return (
    <Text
      sx={{
        ...forwardSx,
        textDecoration: 'line-through',
        whiteSpace: 'nowrap',
        color: 'UIGray2',
        lineHeight: '1.6rem',
        letterSpacing: '-0.01em',
        fontSize: ['1.4rem', '1.6rem'],
        fontWeight: 500,
      }}
    >
      ${regularPrice}
    </Text>
  );
};

export default RegularPrice;
